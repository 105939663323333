var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'select',
    {
      open: _vm.showOptions
    }
  ]},[_c('div',{staticClass:"select__container"},[_c('div',{class:[
        'select__first-state',
        _vm.currentOption ? _vm.currentOption.className : ''
      ],on:{"click":_vm.toggleSelect}},[_c('div',{staticClass:"select__current-value-container"},[_vm._t("currentOptionImage"),_c('p',{class:[
            'select__current-value',
            {
              placeholder: !_vm.currentOption
            }
          ]},[(!_vm.currentOption)?[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]:[_vm._v(" "+_vm._s(_vm.currentOption.label)+" ")]],2)],2),_c('img',{staticClass:"select__arrow-img",attrs:{"src":require("@/assets/img/arrow-select.svg"),"alt":"Arrow"}})]),_c('Transition',[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.showOptions),expression:"showOptions"}],staticClass:"select__list-options"},_vm._l((_vm.optionsNotSelected),function(option,index){return _c('li',{key:`option-${index}`,class:[
            'select__item-option',
            {
              notAccessible: !option.available
            },
            option.className
          ],on:{"click":function($event){return _vm.selectOption(option, index)}}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }