<template>
  <div 
    @click.self="handleClose" 
    :class="[
      'select-project',
      {
        error: invalidAttempt
      }
    ]"
  >
    <div class="select-project__container">
      <button 
        @click="handleClose" 
        class="select-project--btn-close"
      >
        <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 1L1 5M1 1L5 5" stroke="#5C5C5C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>

      <p class="select-project__label">
        Selecione qual site deseja acompanhar
      </p>
      <CommonSelect 
        placeholder="Selecione um projeto*"
        :options="$store.state.projectModule.allUserProjects"
        @change="handleSelect"
      />

      <button 
        @click="handleToEnter"
        :class="[
          'select-project--btn-to-enter',
          {
            disabled: !selected
          }
        ]"
      >
        Acessar
      </button>
    </div>
  </div>
</template>

<script>
import CommonSelect from './CommonSelect.vue';

export default {
  name: 'CommonSelectProject',
  components: {
    CommonSelect
  },
  data() {
    return {
      selected: false,
      invalidAttempt: false
    };
  },  
  methods: {
    handleSelect({ newOptions }) {
      this.invalidAttempt = false;
      this.selected = true;
      this.$store.commit('SET_ALLUSERPROJECTS', newOptions);
    },
    handleToEnter() {
      if (!this.selected) {
        this.invalidAttempt = true;
      } else {
        this.$store.commit(
          'SET_CURRENTPROJECT', 
          this.$store.state.projectModule.allUserProjects.find(project => project.selected)
        );
        this.handleClose();
        this.$router.push('/');
      }
    },
    handleClose() {
      this.$store.commit('SET_SHOWPOPUPSELECTPROJECT', false);
    }
  }
}
</script>
<style>
/* SELECT CUSTOM ERROR */
.select-project.error .select__first-state {
  border-color: #F44336;
}
.select-project.error .select__current-value {
  color: #F44336;
}
/***********************/
</style>
<style scoped>
.select-project {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(28, 28, 28, 0.5);
  cursor: pointer;
}
.select-project__container {
  position: relative;
  padding: 65px 50px 50px;
  width: 100%;
  max-width: 382px;
  background-color: #FFFFFF;
  cursor: default;
}
.select-project--btn-close {
  position: absolute;
  right: 7px;
  top: 7px;
  padding: 0px 10px 10px;
}
.select-project__label {
  margin-bottom: 22px;
  font-size: 16px;
  font-weight: 700;
  color: #5C5C5C;
}
.select-project--btn-to-enter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 13px;
  width: 100%;
  height: 40px;
  background-color: #E85128;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 500;
}
.select-project--btn-to-enter.disabled {
  opacity: 0.3;
}
/* .select-project select {
  font-size: 12px;
  font-weight: 400;
  color: #5C5C5C;
  border: 1px solid #EAEAEA;
}
.select-project select > option {
  border-radius: 0px;
} */
</style>