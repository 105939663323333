var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'select-project',
    {
      error: _vm.invalidAttempt
    }
  ],on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.handleClose.apply(null, arguments)}}},[_c('div',{staticClass:"select-project__container"},[_c('button',{staticClass:"select-project--btn-close",on:{"click":_vm.handleClose}},[_c('svg',{attrs:{"width":"6","height":"6","viewBox":"0 0 6 6","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M5 1L1 5M1 1L5 5","stroke":"#5C5C5C","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('p',{staticClass:"select-project__label"},[_vm._v(" Selecione qual site deseja acompanhar ")]),_c('CommonSelect',{attrs:{"placeholder":"Selecione um projeto*","options":_vm.$store.state.projectModule.allUserProjects},on:{"change":_vm.handleSelect}}),_c('button',{class:[
        'select-project--btn-to-enter',
        {
          disabled: !_vm.selected
        }
      ],on:{"click":_vm.handleToEnter}},[_vm._v(" Acessar ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }