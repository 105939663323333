<template>
  <div 
    :class="[
      'select',
      {
        open: showOptions
      }
    ]"
  >
    <div class="select__container">
      <div 
        @click="toggleSelect" 
        :class="[
          'select__first-state',
          currentOption ? currentOption.className : ''
        ]"
      >
        <div class="select__current-value-container">
          <slot name="currentOptionImage" />
          <p 
            :class="[
              'select__current-value',
              {
                placeholder: !currentOption
              }
            ]"
          >
            <template v-if="!currentOption">
              {{ placeholder }}
            </template>
            <template v-else>
              {{ currentOption.label }}
            </template>
          </p>
        </div>
        <!-- :class="{arrowRotate: showOptions}" -->
        <img 
          src="@/assets/img/arrow-select.svg" 
          alt="Arrow" 
          class="select__arrow-img"
        >
      </div>
      <Transition>
        <ul v-show="showOptions" class="select__list-options">
          <li
            v-for="(option, index) in optionsNotSelected"
            :key="`option-${index}`"
            @click="selectOption(option, index)"
            :class="[
              'select__item-option',
              {
                notAccessible: !option.available
              },
              option.className
            ]"
          >
            {{ option.label }}
          </li>
        </ul>
      </Transition>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    options: {
      type: Array, // options: Array<{ label: string, selected: boolean, available: boolean, className: string }>
      required: true,
      validator(propOptions) {
        if (
          !Array.isArray(propOptions) || 
          (Array.isArray(propOptions) && !propOptions.length)
        ) {
          throw new Error(
            'A prop "options" é obrigatória e deve ser do tipo Array<{ label: string, selected: boolean, available: boolean, className: string }>'
          );
        }

        if (
          !propOptions.every(value => Object.keys(value).includes('label') && typeof value.label === 'string') ||
          !propOptions.every(value => Object.keys(value).includes('selected') && typeof value.selected === 'boolean') ||
          !propOptions.every(value => Object.keys(value).includes('available') && typeof value.available === 'boolean') ||
          !propOptions.every(value => Object.keys(value).includes('className') && typeof value.className === 'string')
        ) {
          throw new Error(
            'A prop "options" é obrigatória e deve ser do tipo Array<{ label: string, selected: boolean, available: boolean, className: string }>'
          );
        }

        return true;
      }
    }
  },  
  computed: {
    currentOption() {
      return this.options.find(option => option.selected);
    },
    optionsNotSelected() {
      return this.options.filter(option => !option.selected);
    }
  },
  data() {
    return {
      showOptions: false,
      timer: 0
    };
  },
  methods: {
    addObserverEvent() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        document.querySelector("body").addEventListener("click", this.closeSelect);
      }, 1);
    },
    removeObserverEvent() {
      clearTimeout(this.timer);
      document.querySelector("body").removeEventListener("click", this.closeSelect);
    },  
    closeSelect() {
      this.showOptions = false;

      this.removeObserverEvent();
    },  
    toggleSelect() {
      this.showOptions = !this.showOptions;
      this.addObserverEvent();
    },
    selectOption(targetOption, optionIndex) {
      this.closeSelect();

      const newOptions = [
        ...this.optionsNotSelected.map(
          (option, index) => {
            return (
              index === optionIndex ? 
                { ...option, selected: true } : 
                { ...option, selected: false }
            );
          }
        ),
      ];
          
      if (this.currentOption) {
        newOptions.push({
          ...this.currentOption,
          selected: false
        });
      }
      
      this.$emit(
        'change', 
        { 
          newOptions, 
          oldOptions: this.options, 
          targetOption,
          optionIndex
        }
      );
    }
  },
};
</script>
<style scoped>
.select {
  position: relative;
  z-index: 1;
  user-select: none;
  -webkit-user-drag: none;
}
.select__first-state {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  padding: 10px 14px;
  border: 1px solid #EAEAEA;
  background-color: #FFF;
  cursor: pointer;
}
.select__current-value-container {
  display: flex;
  align-items: center;
  gap: 8px;
}
.select__current-value-container img {
  display: block;
}
.select__current-value {
  margin-bottom: 0;
  font-size: 12px;
  color: #5C5C5C;
}
.select__current-value.placeholder {
  opacity: 0.7;
}
.select__first-state img {
  transition: transform 0.3s ease-in-out;
}
.select.open .select__arrow-img {
  transform: rotate(180deg);
}
.select__list-options {
  position: absolute;
  top: calc(100% + 2px);
  z-index: 1;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #EFEFEF;  
}
.select__item-option {
  padding: 10px 15px;
  font-size: 12px;
  color: #5C5C5C;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.select__item-option:not(.notAccessible) {
  cursor: pointer;
}
.select__item-option:not(.notAccessible):hover {
  background-color: #E9E9E9;
}
.select__item-option.notAccessible {
  opacity: 0.3;
}
</style>